import "./messageForm.css";
import Avatar from '@mui/material/Avatar';

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: name.includes(' ') ? `${name.split(' ')[0][0]}${name.split(' ')[1][0]}` : `${name.split(' ')[0][0]}`,
  };
}

function MessageForm({ message, own, icon, id }) {
  return (
    <div className={own ? "message own" : "message"}>
      <div className="messageTop">
        {console.log("icon : ", icon)}
        {
          own === false ?
          <div className="messageLeft">
            {
              icon != undefined ?
                <img
                  className="messageImg"
                  src={icon}
                  alt=""
                />
                :
                <Avatar {...stringAvatar(id)} />
            }
          </div>
          :
          <></>
        }
        
        <div className="messageRight">
          <div className="messageId">{id}</div>
          <div className="messageText">{message}</div>
        </div>
      </div>
    </div>
  );
}

export default MessageForm;
