import { useSubscription } from "mqtt-react-hooks";
import React, { useEffect, useRef, useState } from "react";
import MessengerThemeComponent from "./MessengerThemeComponent";
import ToastThemeComponent from "./ToastThemeComponent";
import TickerThemeComponent from "./TickerThemeComponent";
import useStore from "../data/Store";
import ErrorBoundary from "../components/ErrorBoundary";
import axios from "axios";

//http://test3.bonkab.com/?topic={채널명}&id={내아이디}

function MainPage() {
  const { topic, setTopic, access_token, setAccessToken } = useStore();
  const [currentTalkRoom, setTalkRoom] = useState(topic);
  const [myId, setMyId] = useState("biden"); //default id
  const { message } = useSubscription("#");
  const [userInfo, setUserInfo] = useState([]);
  const [theme, setTheme] = useState("toast"); //1. messenger 2. toast

  const [messages, setMessages] = useState([]);
  const [textAreaValue, setTextAreaValue] = useState("");

  useEffect(() => {
    const queryParams = new URLSearchParams(
      decodeURIComponent(window.location.search)
    );
    console.log("queryParams : ", queryParams);

    const currentRoom = queryParams.get("topic");
    if (currentRoom) {
      setTalkRoom(currentRoom);
      setTopic(currentRoom);
    }

    const currentId = queryParams.get("id");
    if (currentId) setMyId(currentId);

    const currentTheme = queryParams.get("theme");
    if (currentTheme) setTheme(currentTheme);

    const currentToken = queryParams.get("access_token");
    if (currentToken) setAccessToken(currentToken);

    console.log("current Room & ID : ", currentRoom, currentId);
  }, []);

  useEffect(() => {
    console.log("useEffect topic");
    setTalkRoom(topic);
    setMessages([]);
    if (window.tvlog) {
      window.tvlog("new topic is ", topic);
      window.tvlog("currentTalkRoom is ", currentTalkRoom);
      window.tvlog("message topic", message?.topic);
    }
  }, [topic]);

  useEffect(async () => {
    console.log("new message arrived : ", message);
    if (window.tvlog) window.tvlog("new message arrived : ", message);

    if (message) {
      const { topic: topicValue, message: messageValue } = message;
      console.log("parsed messageValue : ", messageValue);

      if (topicValue !== currentTalkRoom) {
        if (window.tvlog)
          window.tvlog(
            "topic is: ",
            topicValue,
            " but current is ",
            currentTalkRoom
          );
        return;
      }

      try {
        let parsedMsg = JSON.parse(messageValue);
        //let receiveData = message?.text;
        console.log("parded parsedMsg.message: ", parsedMsg.message);
        console.log("parded parsedId.id: ", parsedMsg.id);
        console.log("topic value : ", topicValue);

        if (isExistUserInfo(parsedMsg.id)) {
          console.log("already exist id...");
        } else {
          console.log("add userInfo url... before");
          await getUserInfo(parsedMsg.id);
          console.log("add userInfo url... after");
        }

        setTimeout(() => {
          setMessages((prev) => [
            ...prev,
            { message: parsedMsg.message, id: parsedMsg.id },
          ]);
        }, 500);
      } catch (err) {
        console.log("errrrr");
        // setMessages((prev) => [
        //   ...prev,
        //   { message: message?.message, id: "trump" },
        // ]);
      }
    }
  }, [message]);

  const isExistUserInfo = (newId) => {
    console.log("isExist check new id : ", newId);
    console.log("isExist check userinfo :", userInfo);

    return userInfo.some((user) => user.id === newId);
  };

  const getUserInfo = async (newId) => {
    console.log("getUserInfo access_token : ", access_token);
    await axios
      .get("http://bonkab.com:49168/getUserInfo", {
        headers: {
          authorization: `Bearer ${access_token}`,
        },
        params: {
          id: newId,
        },
      })
      .then((res) => {
        if (res.data.status === 200) {
          console.log("getUserInfo success : ", res);
          setUserInfo((prev) => [
            ...prev,
            { id: newId, url: res.data.url, nickname: res.data.nickname },
          ]);
        } else {
          console.log("getUserInfo fail : ", res);
        }
      })
      .catch((err) => {
        console.log("getUserInfo err : ", err);
      });
  };

  return (
    <>
      <ErrorBoundary>
        {theme === "toast" ? (
          <ToastThemeComponent
            message={message}
            messages={messages}
            myId={myId}
            myRoom={topic}
            userInfo={userInfo}
          />
        ) : theme === "ticker" ? (
          <TickerThemeComponent
            message={message}
            messages={messages}
            myId={myId}
            myRoom={topic}
            userInfo={userInfo}
          />
        ) : (
          <MessengerThemeComponent
            message={message}
            messages={messages}
            myId={myId}
            myRoom={topic}
            userInfo={userInfo}
          />
        )}
      </ErrorBoundary>
    </>
  );
}

export default MainPage;
