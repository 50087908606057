import React, { useEffect, useRef } from "react";
import styled, { css } from "styled-components";

const ToastBox = styled.div`
  display: flex;
  flex-direction: column;
  text-color: white;
  position: absolute;
  overflow: visible;
  top: 0px;
  left: 0px;
  opacity:0;
`;

const IdPart = styled.div`
  color: ${(props) => props.textColor};
  font-weight: 700;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-shadow: 1px 1px 1px grey;
  line-height: normal;
  font-size: 2em;
`;

const MessagePart = styled.div`
  color: ${(props) => props.textColor};
  font-weight: 500;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  text-shadow: 1px 1px 1px grey;
  line-height: normal;
  font-size: 1.8em;
`;

function ToastForm({ toastMessage }) {
  const toastRef = useRef();
  useEffect(() => {
    console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!toast create width : " + toastRef.current.offsetWidth + " height : " + toastRef.current.offsetHeight);

    const enableWidth = window.innerWidth - toastRef.current.offsetWidth;
    const enableHeight = window.innerHeight - toastRef.current.offsetHeight;

    console.log("toast create enableWidth : " + enableWidth + " enableHeight : " + enableHeight);

    const tranX = Math.floor(Math.random() * enableWidth);
    const tranY = Math.floor(Math.random() * enableHeight);

    toastRef.current.style.transform = `translateX(${tranX}px) translateY(${tranY}px)`;

    toastRef.current.style.transition = `opacity 1s`;
    toastRef.current.style.opacity = 1;
  }, []);
  return (
    <ToastBox ref={toastRef}>
      <IdPart textColor={toastMessage.textColor}>{toastMessage.id}</IdPart>
      <MessagePart textColor={toastMessage.textColor}>
        {toastMessage.message}
      </MessagePart>
    </ToastBox>
  );
}

export default ToastForm;
