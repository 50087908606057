import React, { Component } from "react";

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    // this.state = { hasError: false };
    this.state = { error: null, errorInfo: null };
  }
  static getDerivedStateFromError(error) {
    // 다음 렌더링에서 폴백 UI가 보이도록 상태를 업데이트 합니다.
    return { hasError: true };
  }
  componentDidCatch(error, errorInfo) {
    // 에러 리포팅 서비스에 에러를 기록할 수도 있습니다.
    // logErrorToMyService(error, errorInfo);
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
  }
  render() {
    if (this.state.error) {
      // 폴백 UI를 커스텀하여 렌더링할 수 있습니다.
      return (
        <div>
          <h2>Something went wrong.</h2>
          <h3 style={{ whiteSpace: "pre-wrap" }}>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo.componentStack}
          </h3>
        </div>
      );
    }
    return this.props.children;
  }
}
