import React, { useEffect, useRef, useState } from "react";
import "./messenger.css";
import { useMqttState, useSubscription } from "mqtt-react-hooks";
import Tobbar from "../components/topbar/Topbar";
import MessageForm from "../components/message/MessageForm";
import SendIcon from '@mui/icons-material/Send';

function MessengerThemeComponent({ message, messages, myId, myRoom, userInfo }) {
  const [newMessage, setNewMessage] = useState("");
  const scrollRef = useRef(null);
  const inputRef = useRef(null);
  const { client } = useMqttState();
  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const handleSubmit = () => {
    if (newMessage === "") {
      return;
    }
    const jsonFormat = {
      id: myId,
      message: newMessage,
    };

    setNewMessage("");
    client.publish(myRoom, JSON.stringify(jsonFormat));
    inputRef.current.focus();
  };

  const handleKeyPress = e => {
    if(e.key === 'Enter') {
      handleSubmit();
    }
  }

  const getPhoto = (id) => {
    const user = userInfo.filter(user => user.id === id);
    return user[0]?.url;
  }

  const getNickName = (id) => {
    const user = userInfo.filter(user => user.id === id);
    const result = user[0]?.nickname;

    if (result === undefined || result === "") {
      return id;
    } else {
      return result;
    }
  }

  return (
    <div className="totalBody">
      <div className="leftColumn"></div>
      <div className="rightColumn">
        <Tobbar topic={myRoom}/>
        {/*<div>{`Connect Status : ${client} | Current Topic : ${message?.topic}`}</div>*/}
        {/* <div className="roomName">{message?.topic}</div> */}
        <div style={{ display: "flex", flexDirection: "column" }}></div>

        <div className="messenger">
          <div className="chatBox">
            <div className="chatBoxWrapper">
              <div className="chatBoxTop">
                {messages.map((m) => (
                  <div ref={scrollRef}>
                    <MessageForm
                      message={m.message}
                      own={myId === m.id}
                      icon={getPhoto(m.id)}
                      id={getNickName(m.id)}
                    />
                  </div>
                ))}
              </div>
              <div className="chatBoxBottom">
                <input
                  className="chatMessageInput"
                  placeholder="여기를 눌러 입력할 수 있어요."
                  onChange={(e) => setNewMessage(e.target.value)}
                  value={newMessage}
                  onKeyPress={handleKeyPress}
                  ref={inputRef}
                ></input>
                <button className="chatSubmitButton" onClick={handleSubmit}>
                  <SendIcon/>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MessengerThemeComponent;
