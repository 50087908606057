import { Route } from "react-router-dom";
import MainPage from "./views/MainPage";
import TvHandler from "./components/TvHandler";
import { useState } from "react";
import ErrorBoundary from "./components/ErrorBoundary";

function App() {
  return (
    <div>
      <ErrorBoundary>
        <TvHandler />
      </ErrorBoundary>
      <ErrorBoundary>
        <Route exact path="/" component={MainPage} />
      </ErrorBoundary>
    </div>
  );
}

export default App;
