import create from 'zustand';

// set method로 상태 변경 가능
const useStore = create(set => ({
  topic: "test",
  setTopic: (newTopic) => set({ topic: newTopic }),
  access_token: "",
  setAccessToken: (newToken) => set({ access_token: newToken }),
}));

export default useStore;