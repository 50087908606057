import React, { useEffect } from "react";

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import Ticker from "react-ticker";
import "./ticker.css";
import { red } from '@mui/material/colors';
import { fontSize } from "@mui/system";

let messageList = [];

function TickerThemeComponent({ message, messages, myId, myRoom, userInfo }) {
  useEffect(() => {
    if (message) {
      if (message.topic !== myRoom) {
        if (window.tvlog)
          window.tvlog(
            "topic is: ",
            message.topic,
            " but current is ",
            myRoom
          );
        return;
      }

      setTimeout(() => {
        console.log("TickerThemeComponent puch message...");
        try {
          const parsedMsg = JSON.parse(message.message);
          messageList.push(parsedMsg);
        } catch(err) {
          console.log("ticker err");
        }
      }, 1000);
    }
  }, [message]);

  const getPhoto = (id) => {
    const user = userInfo.filter(user => user.id === id);
    return user[0]?.url;
  }

  const getNickName = (id) => {
    const user = userInfo.filter(user => user.id === id);
    const result = user[0]?.nickname;

    if (result === undefined || result === "") {
      return id;
    } else {
      return result;
    }
  }

  return (
    <div style={{backgroundColor: "hsla(0,0%,100%,.7)", height:90, alignItems:"center"}}>
      <Ticker
        offset="run-in"
        speed={15}
        onNext={(index) => {
          // console.log(index);
        }}
      >
        {({ index }) => {
          let msg = {
            id:"",
            message:"",
            photo:"",
          };

          if (messageList.length > 0) {
            msg.id = getNickName(messageList[0].id);
            msg.message = messageList[0].message;
            msg.photo = getPhoto(messageList[0].id);
            // msg = getNickName(messageList[0].id) + ": " + messageList[0].message;
            messageList.shift();
          }

          return (
            <>
              {" "}
              {msg?.message !== "" ? (
                // <div style={{display:"inline"}}>
                //   {
                //     msg.photo !== "" ?
                //     <img src={msg.photo} width="30px" height="30px"/> : <></>
                //   }
                //   <p style={{ whiteSpace: "nowrap", fontFamily:"HallymGothic-Regular" }}>{msg.id + "" + msg.message}</p>
                // </div>
                <Card sx={{ maxWidth: 1500 }} style={{ border: "none", boxShadow: "none", backgroundColor:"transparent"}}>
                  <CardHeader
                    avatar={
                      <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe"
                        src={msg.photo}>
                      </Avatar>
                    }
                    title={msg.id}
                    subheader={msg.message}
                    titleTypographyProps={{
                      fontSize: 25,
                      fontFamily:"ONE-Mobile-POP"
                    }}
                    subheaderTypographyProps={{
                      fontSize: 23,
                      fontFamily:"ONE-Mobile-POP"
                    }}
                  />
                </Card>
              ) : (
                <p style={{ opacity: "0"}}>{"+++++"}</p>
              )}
            </>
          );
        }}
      </Ticker>
    </div>
  );
}

export default TickerThemeComponent;
