import { useCallback, useEffect, useState } from "react";
import useStore from "../data/Store";

function TvHandler(props) {
  const [error, setError] = useState(null);
  const [clientId, setClientId] = useState();
  const { topic, setTopic, access_token, setAccessToken } = useStore();
  const keyListener = useCallback(
    (e) => {
      try {
        if (e.key) {
          const keyEvent = {
            code: e.code,
            key: e.key,
            keyCode: e.keyCode,
            type: e.type,
          };

          // window.tvlog(keyEvent);
          if (clientId) {
            window.connectManager.sendMessage(clientId, keyEvent);
          }

          if (e.key === "GoBack") {
            window.close();
          }
        }
      } catch (error) {
        setError(error);
      }
    },
    [clientId]
  );
  const clickListener = useCallback(
    (e) => {
      try {
        keyListener({
          code: "Enter",
          key: "Enter",
          keyCode: -1,
          type: "click",
        });
      } catch (error) {
        setError(error);
      }
    },
    [keyListener]
  );

  useEffect(() => {
    window.addEventListener("keydown", keyListener);
    window.addEventListener("click", clickListener);
    return () => {
      window.removeEventListener("keydown", keyListener);
      window.removeEventListener("click", clickListener);
    };
  }, [clickListener, keyListener]);
  useEffect(() => {
    window.connectManager = new window.connectsdk.ConnectManager();
    window.connectManager.on("join", function (client) {
      try {
        window.tvlog("client joined with id " + client.id);
        setClientId(client.id);
      } catch (error) {
        setError(error);
      }
    });
    window.connectManager.on("message", function (data) {
      try {
        window.tvlog("client sendmessage", data);
        var from = data.from;
        var message = data.message;
        var messageString;
        var isJSON = false;

        if (typeof message == "string") {
          messageString = message;
        } else if (typeof message == "object") {
          messageString = JSON.stringify(message);
          isJSON = true;
        }
        window.tvlog(
          `Received message(JSON ${isJSON}) from ${from}:`,
          messageString
        );

        if (messageString.includes("topic:")) {
          messageString = messageString.replace("topic:", "");
          window.tvlog("replace...", messageString);
          setTopic(messageString);
        }
      } catch (error) {
        setError(error);
      }
    });
    window.connectManager.init();
  }, []);

  return <div>{error && <h1>error:{error}</h1>}</div>;
}

export default TvHandler;
