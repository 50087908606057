import { Notifications, Person } from "@material-ui/icons";

import { Link } from "react-router-dom";

import "./topbar.css";

function Topbar(props) {
  //const PF = process.env.REACT_APP_PUBLIC_FOLDER;
  return (
    <div className="topbarContainer">
      <div className="topbarLeft">
        <Link to="/" style={{ textDecoration: "none" }}>
          <span className="logo">{props.topic}</span>
        </Link>
      </div>
      {/*<div className="topbarCenter">
        <div className="searchbar">
          <Search className="searchIcon" />
          <input
            placeholder="Search for friend, post or video"
            className="searchInput"
          />
        </div>
      </div>*/}
      {/* <div className="topbarRight"> */}
        {/*<div className="topbarLinks">
          <span className="topbarLink">Homepage</span>
          <span className="topbarLink">Timeline</span>
        </div>*/}
        {/* <div className="topbarIcons"> */}
          {/* <div className="topbarIconItem">
            <Person />
            <span className="topbarIconBadge">5</span>
          </div> */}
          {/*<div className="topbarIconItem">
            <Chat />
            <span className="topbarIconBadge">2</span>
          </div>*/}
          {/* <div className="topbarIconItem">
            <Notifications />
            <span className="topbarIconBadge">1</span>
          </div> */}
        {/* </div>
      </div> */}
    </div>
  );
}
export default Topbar;
