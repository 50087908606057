import React, { useEffect, useRef, useState } from "react";
import ToastForm from "../components/message/ToastForm";

const COLOR_TEXT = [
  "#000000",
  "#ff0000",
  "#00ff00",
  "#0000ff",
  "#00ffff",
  "#ff00ff",
  "#ffff00",
];
const COLOR_BACKGROUND = [""];
const NUM_SHOW_TOAST_MAX = 20;
const TIME_TOAST_SHOW = 15000;

function ToastThemeComponent({ message, messages, myId, myRoom, userInfo }) {
  const [toastMessage, setToastMessage] = useState([]);
  const [toastShow, setToastShow] = useState([]);
  const arrayNum = useRef(0);

  useEffect(() => {
    if (message) {
      if (message.topic !== myRoom) {
        if (window.tvlog)
          window.tvlog(
            "topic is: ",
            message.topic,
            " but current is ",
            myRoom
          );
        return;
      }

      if (message?.message) {
        try {
          const parsedMsg = JSON.parse(message.message);
  
          const textColor =
            COLOR_TEXT[Math.floor(Math.random() * COLOR_TEXT.length)];
    
          if (arrayNum.current >= NUM_SHOW_TOAST_MAX) {
            arrayNum.current = 0;
          }
    
          setToastMessage((prev) => {
            let newArray = [...prev];
            newArray[arrayNum.current] = {
              id: parsedMsg.id,
              message: parsedMsg.message,
              textColor: textColor,
            };
            return newArray;
          });
    
          setToastShow((prev) => {
            let newArray = [...prev];
            newArray[arrayNum.current] = true;
            return newArray;
          });
        } catch(err) {
          console.log("toast err :", err);
        }
      }
    }
  }, [message]);

  useEffect(() => {
    if (toastShow[arrayNum.current] === true) {
      setTimeout(
        (index) => {
          setToastShow((prev) => {
            let newArray = [...prev];
            newArray[index] = false;
            return newArray;
          });
        },
        TIME_TOAST_SHOW,
        arrayNum.current
      );
      arrayNum.current++;
    }
  }, [toastShow]);

  useEffect(() => {
    console.log('width : ', window.innerWidth);
    console.log('height : ', window.innerHeight);
  },[]);

  return (
    <div>
      {toastShow.map((item, index) => {
        if (item === true) {
          return <ToastForm toastMessage={toastMessage[index]} />;
        }
      })}
    </div>
  );
}

export default ToastThemeComponent;
